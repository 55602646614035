//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {dataUtil} from "@/utils/data-util";
import ThematicTypeContainer from "@/views/business/thematic-map/thematic-type-container";

export default {
	name: "overview-of-smoking-area",
	components: {ThematicTypeContainer},
	inject: ['showLoading', 'hideLoading'],
	computed: {
		areaTree() {
			return this.$store.state['areaManagement'].areaTree;
		},

	},
	data() {
		return {
			// 行政区域
			casProps: {
				value: 'code',
				label: 'name',
				children: 'childrenList',
				checkStrictly: true,
			},
			areaList: [530000],
			// 大类
			typeOptions: [{
				value: 'area',
				label: '行政区'
			}, {
				value: 'tobaccoArea',
				label: '种植区'
			}],
			typeValueValue: 'area',
			// 年份
			particularYearList: [],
			particularYear: 2020,
			// 字典值
			dict: [],
			tobaccoArea: '1'
		};
	},
	async mounted() {
		// 请求行政区划树
		await this.$store.dispatch('areaManagement/getAreaTree');
		// 初始化时间
		this.particularYearList = dataUtil.getDateTimes(false);
		// 获取种植区
		let res = await this.$store.dispatch('dict/getDictEntity', {
			type: ['tobacco_area'],
		});
		this.dict = res.data;
		// 初始化种植区为空
		await this.$store.dispatch('region/setSelectedTobaccoArea', null);
		// 初始化
		await this.initialization();
	},
	methods: {
		/**
		 * 初始化一些数据
		 */
		initialization() {
			// 默认为云南省
			this.$store.dispatch('region/setSelectedRegionObject', this.areaTree[0]);
			// 获取行政区名称列表
			let res = dataUtil.getAreaName(this.areaList, this.areaTree);
			if (res) {
				// 更改vue的行政区值
				this.$store.dispatch("tobaccoAnalyse/changeParamsSingle",
					{
						'key': 'areaCode',
						'value': res
					}
				)
			}
			// 更改年份选择值
			this.$store.dispatch('tobaccoAnalyse/changeParamsSingle', {
				key: 'year',
				value: this.particularYear,
			});
		},

		/**
		 * 更改大类
		 */
		handlerClickRegionType() {
			this.$store.dispatch('region/setRegionType', this.typeValueValue);
			if (this.typeValueValue === 'tobaccoArea') {
				this.$store.dispatch('region/setSelectedTobaccoArea', this.dict[0]);
			}
			this.$emit('handlerClickRegionType', this.typeValueValue);
		},

		/**
		 * 搜索的行政区划发生变化
		 */
		changeArea() {
			// 获取行政区名称列表
			let res = dataUtil.getAreaName(this.areaList, this.areaTree);
			if (res) {
				// 更改vue的行政区值
				this.$store.dispatch("tobaccoAnalyse/changeParamsSingle",
					{
						'key': 'areaCode',
						'value': res
					}
				)
			}
			// 获取当前行政区对象
			this.parentObject = dataUtil.getAreaObject(this.areaList.at(-1), this.areaTree);
			// 当前的行政区划
			this.$store.dispatch('region/setSelectedRegionObject', this.parentObject);
			this.search();
		},
		/**
		 * 更改种植区划
		 */
		handlerClickTobaccoArea() {
			// 获取种植区对象
			let tobaccoAreaObject = {};
			this.dict.forEach(item => {
				if (item.value === this.tobaccoArea) {
					tobaccoAreaObject = item;
				}
			})
			this.$store.dispatch('region/setSelectedTobaccoArea', tobaccoAreaObject);
			this.$emit('handlerClickTobaccoArea', tobaccoAreaObject);
		},

		/**
		 * 更改时间
		 */
		changeParticularYear() {
			// 更改年份选择值
			this.$store.dispatch('tobaccoAnalyse/changeParamsSingle', {
				key: 'year',
				value: this.particularYear,
			});
			this.search();
			this.$emit('changeParticularYear', this.particularYear);
		},

		/**
		 * 更改分类
		 */
		handlerThematicTypeClick() {
			this.$emit('handlerThematicTypeClick');
		},

		/**
		 * 查询数据
		 * @returns {Promise<void>}
		 */
		async search() {
			this.showLoading('加载样点数据', '#eeeeee2e');
			await this.setRandomColor();
			// 仅请求常规化学成分的样点
			await this.$store.dispatch('tobaccoAnalyse/changeType', ['2', '1']);
			await this.$store.dispatch('tobaccoAnalyse/search');
			this.hideLoading();
		},

		/**
		 * 获取随机颜色点
		 */
		setRandomColor() {
			// 样点随机颜色
			let randomColorList = [
				'#7BFF46',
				'#FFF446',
				'#46FFB3',
				'#46F2FF',
				'#94DEF9',
				'#F9DA94',
			];
			let index = Math.floor(Math.random() * randomColorList.length);
			let color = randomColorList[index];
			this.$store.dispatch(
				'tobaccoAnalyse/setRandomRGBColor',
				color
			);
		},
	}
}
