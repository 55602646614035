//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LoadingForMap from '@/components/loading/LoadingForMap.vue';
import * as Cesium from 'cesium';
import 'cesium/Build/Cesium/Widgets/widgets.css';
import { getGeoServerApiParams } from '@/utils/geoserver-params';
import config from '@/config';

window.CESIUM_BASE_URL = '/static/Cesium/';
Cesium.Ion.defaultAccessToken =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJlZTFiN2RkZi1iNTBmLTRiYWItYWMwYi02ZGZlMzQxZmVlOGYiLCJpZCI6MzE2MDcsInNjb3BlcyI6WyJhc3IiLCJnYyJdLCJpYXQiOjE1OTU2NDY1NTl9.wtYnBtXVyGpQD3DK9JlUy8tMo-ocpKJeaUF6BiYl-Bs';

export default {
    name: 'thematicMap3d',
    inject: ['showLoading', 'hideLoading'],
    components: {
        LoadingForMap,
    },
    computed: {
        administrativeDivision() {
            return this.$store.state['thematicMap'].administrativeDivision;
        },
        thematicLayersAndStylesObj() {
            return this.$store.state['thematicMap'].thematicLayersAndStylesObj;
        },
        selectedThematicType() {
            return this.$store.state['thematicMap'].selectedThematicType;
        },
        selectedRegionObject() {
            return this.$store.state['region'].selectedRegionObject;
        },
        selectedTobaccoArea() {
            return this.$store.state['region'].selectedTobaccoArea;
        },
        regionType() {
            return this.$store.state['region'].regionType;
        },
        list() {
            return this.$store.state['tobaccoAnalyse'].list;
        },
        randomRGBColor() {
            return this.$store.state['tobaccoAnalyse'].randomRGBColor;
        },
    },
    watch: {
        list(value) {
            if (value) {
                this.getList(true);
            }
        },
    },
    data() {
        return {
            viewer: null,
            loading: false,
            zoomInPointerHeight: 18000,
            currentImageryLayer: null, // 当前展示的专题图
            showFeatures: null, // 当前展示的行政区划
            allLine: [],
            initPoint: Cesium.Cartesian3.fromDegrees(106.3983154296875, 25.31208186007361, 1800000), // 默认相机位置
            chooseYear: 2020,
            loadingForMapVisible: false,
            loadingForMapMsg: '正在加载地图',
            currentArea: {
                imageryLayers: null,
            },
        };
    },
    methods: {
        initMap: function () {
            this.viewer = new Cesium.Viewer('thematicMap', {
                terrainProvider: Cesium.createWorldTerrain(),
                geocoder: false, //右上角 搜索
                homeButton: false, //右上角 Home
                sceneModePicker: false, //右上角 2D/3D切换
                baseLayerPicker: false, //右上角 地形
                navigationHelpButton: false, //右上角 Help
                animation: false, // 左下角 圆盘动画控件
                timeline: false, //时间轴
                shouldAnimate: true,
                fullscreenButton: false, //右下角 全屏控件
                vrButton: false, // 如果设置为true，将创建VRButton小部件。
                scene3DOnly: true, // 每个几何实例仅以3D渲染以节省GPU内存
                infoBox: false, //隐藏点击要素后的提示信息
                selectionIndicator: false,
            });

            this.viewer._cesiumWidget._creditContainer.style.display = 'none'; //隐藏版本信息
            this.viewer.scene.skyBox = new Cesium.SkyBox({
                sources: {
                    positiveX: require('@/assets/images/skyBox/tycho2t3_80_px.jpg'),
                    negativeX: require('@/assets/images/skyBox/tycho2t3_80_mx.jpg'),
                    positiveY: require('@/assets/images/skyBox/tycho2t3_80_py.jpg'),
                    negativeY: require('@/assets/images/skyBox/tycho2t3_80_my.jpg'),
                    positiveZ: require('@/assets/images/skyBox/tycho2t3_80_pz.jpg'),
                    negativeZ: require('@/assets/images/skyBox/tycho2t3_80_mz.jpg'),
                },
            });
            // 最小缩放距离
            this.viewer.scene.screenSpaceCameraController.minimumZoomDistance = 800;

            // 监听每次Cesium资源加载，关闭加载动画
            let helper = new Cesium.EventHelper();
            helper.add(this.viewer.scene.globe.tileLoadProgressEvent, (e) => {
                this.loadingForMapVisible = true;
                if (e == 0) {
                    // 关闭加载动画
                    this.loadingForMapVisible = false;
                }
            });
            setTimeout(() => {
                this.tileLoadProgressOver();
            }, 4000);
        },
        cameraMoveHander: function () {
            let height = this.viewer.camera.positionCartographic.height;
            this.blueImage.show = height >= this.zoomInPointerHeight + 300000;
        },
        tileLoadProgressOver() {
            // 相机飞行到云南省
            this.viewer.camera.flyTo({
                destination: this.initPoint,
                duration: 3,
                complete: () => {
                    // 加载当前区域类型边界（使用wms的服务）
                    this.loadCurrentAreaBord();
                    // 加载专题图
                    this.loadThematicByType();
                },
            });
        },

        // 加载当前区域类型边界
        loadCurrentAreaBord() {
            // 销毁已有的图层资源
            if (this.viewer.imageryLayers.contains(this.currentArea.imageryLayers)) {
                this.viewer.imageryLayers.remove(this.currentArea.imageryLayers);
                this.currentArea.imageryLayers = null;
            }
            let key = this.regionType === 'area' ? '2' : 'tobaccoArea';
            let layers = this.administrativeDivision[key].layers;
            let urlParams = getGeoServerApiParams(layers, 'wms');
            // 添加当前区域类型边界
            this.currentArea.imageryLayers = this.viewer.imageryLayers.addImageryProvider(
                new Cesium.WebMapServiceImageryProvider({
                    url: config.qyGeoApi + '?' + urlParams,
                    layers: layers,
                    parameters: {
                        STYLES: '810a4176a674f0965a61a3cd5adf8102',
                        transparent: true,
                        format: 'image/png',
                    },
                })
            );
        },

        // 加载不同的专题图
        loadThematicByType() {
            // 销毁已有的图层资源
            if (this.viewer.imageryLayers.contains(this.currentImageryLayer)) {
                this.viewer.imageryLayers.remove(this.currentImageryLayer);
                this.currentImageryLayer = null;
            }

            // 根据专题图分类获取专题图layers和STYLES
            // debugger
            if (!this.thematicLayersAndStylesObj) {
                return;
            }
            let obj = this.thematicLayersAndStylesObj[this.selectedThematicType];
            if (!obj) {
                return;
            }

            let urlParams = getGeoServerApiParams(obj.layers, 'wms');
            let webMapServiceImageryProvider = new Cesium.WebMapServiceImageryProvider({
                url: config.qyGeoApi + '?' + urlParams,
                layers: obj.layers,
                parameters: {
                    STYLES: obj.STYLES,
                    transparent: true,
                    format: 'image/png',
                },
            });
            this.currentImageryLayer = this.viewer.imageryLayers.addImageryProvider(webMapServiceImageryProvider, 1);
        },

        formatGeoJson: async function () {
            let url = '';
            if (this.regionType === 'area') {
                // 根据行政区划分级来请求不同的layers
                let level = this.selectedRegionObject.level;
                let layers = null;
                let urlParams = null;
                switch (level) {
                    // 需要获取下级的行政区划
                    case 2:
                        layers = this.administrativeDivision[3].layers;
                        urlParams = getGeoServerApiParams(layers, 'wfs');
                        url = `${config.qyGeoApi}?${urlParams}&cql_filter=City_code='${this.selectedRegionObject.code}'&typeName=${layers}&service=wfs&version=2.0.0&request=GetFeature&outputFormat=application%2Fjson`;
                        break;
                    case 3:
                        layers = this.administrativeDivision[4].layers;
                        urlParams = getGeoServerApiParams(layers, 'wfs');
                        url = `${config.qyGeoApi}?${urlParams}&cql_filter=County_cod='${this.selectedRegionObject.code}'&typeName=${layers}&service=wfs&version=2.0.0&request=GetFeature&outputFormat=application%2Fjson`;
                        break;
                    default:
                        layers = this.administrativeDivision[level].layers;
                        urlParams = getGeoServerApiParams(layers, 'wfs');
                        url = `${config.qyGeoApi}?${urlParams}&cql_filter=code='${this.selectedRegionObject.code}'&typeName=${layers}&service=wfs&version=2.0.0&request=GetFeature&outputFormat=application%2Fjson`;
                }
            } else {
                // 根据种植区分区获取不同的区域geojson
                let layers = this.administrativeDivision['tobaccoArea'].layers;
                let urlParams = getGeoServerApiParams(layers, 'wfs');
                url = `${config.qyGeoApi}?${urlParams}&cql_filter=value='${this.selectedTobaccoArea.value}'&typeName=${layers}&service=wfs&version=2.0.0&request=GetFeature&outputFormat=application%2Fjson`;
            }
            return await fetch(url).then((resp) => {
                return resp.json();
            });
        },
        async loadGeoJson() {
            this.showLoading('正在加载', '#eeeeee2e');
            // 清除原有的dataSources
            if (this.showFeatures) {
                this.viewer.dataSources.remove(this.showFeatures);
                this.showFeatures = null;
            }
            let checkFault = true;
            if (this.regionType === 'area') {
                checkFault =
                    this.selectedRegionObject.level === 1 ||
                    !(this.selectedRegionObject && this.selectedRegionObject.code);
                // 下钻到云南省的下级行政区划，隐藏全省的行政区划
                if (this.currentArea.imageryLayers) {
                    this.currentArea.imageryLayers.show = this.selectedRegionObject.level === 1;
                }
            } else {
                checkFault = !(this.selectedTobaccoArea && this.selectedTobaccoArea.value);
            }
            if (checkFault) {
                this.hideLoading();
                // 相机飞行到云南省
                this.viewer.camera.flyTo({
                    destination: this.initPoint,
                    duration: 2,
                    complete: () => {},
                });
                return;
            }

            let geoJson = await this.formatGeoJson();
            await this.$store.dispatch('region/setSelectedRegionGeojson', geoJson);
            let promise = Cesium.GeoJsonDataSource.load(geoJson);
            promise.then((dataSource) => {
                this.showFeatures = dataSource;
                this.viewer.dataSources.add(dataSource);
                let entities = dataSource.entities.values;
                for (let o = 0; o < entities.length; o++) {
                    let entity = entities[o];
                    entity.polygon.outlineWidth = 3;
                    entity.polygon.fill = false;
                    entity.polygon.outline = false;
                    entity.polygon.outlineColor = Cesium.Color.fromCssColorString('#d52323');
                    entity.polyline = {
                        positions: entity.polygon.hierarchy._value.positions,
                        width: entity.polygon.outlineWidth,
                        material: Cesium.Color.fromCssColorString('#d52323'),
                    };
                }
            });
            this.viewer.flyTo(promise, {
                offset: new Cesium.HeadingPitchRange(0, -2, 0),
            });
            this.hideLoading();
        },

        // 获取查询出来的数据
        getList(bol) {
            this.showLoading('加载样点数据', '#eeeeee2e');
            this.viewer.entities.removeAll();
            if (!bol) {
                this.hideLoading();
                return;
            }
            let imageBlue = require('@/assets/images/contrast/tobacco.png');
            this.list.forEach((item) => {
                let point = this.viewer.entities.add({
                    position: Cesium.Cartesian3.fromDegrees(Number(item.longitude), Number(item.latitude)),
                    billboard: {
                        image: imageBlue,
                        heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
                        scale: 1.2,
                        color: Cesium.Color.fromCssColorString(this.randomRGBColor),
                    },
                });
                item.pointId = point.id;
            });
            setTimeout(() => {
                this.hideLoading();
            }, 200);
        },
    },
    mounted() {
        this.$store.dispatch('thematicMap/setAdministrativeDivision');
        this.initMap();
    },
};
