//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { clipStatisticWithGroup } from '@/utils/geoserver-params';
// import { thematicDatas } from '@/assets/datas/datas.js';
// import { getGeoServerApiParams } from '@/utils/geoserver-params';
// import config from '@/config';
export default {
    name: 'analyticalChartBar',
    props: {
        headerTitle: {
            type: String,
            default: '2016年云南省下级区域降雨量分析',
        },
        chartLoading: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            option: { 
                title: {
                    show: false,
                    text: '暂无数据',
                    left: 'center',
                    top: 'middle',
                    textStyle: {
                        color: '#fff',
                        fontSize: '32px'
                    }
                },
                toolbox: {
					show: false,
					feature: {//可下载为图
						saveAsImage: {show: true}
					}
				},
                legend: {
                    type: 'scroll',
                    top: 0,
                    pageIconColor: '#fff',
                    pageTextStyle: {
                        color: '#fff',
                    },
                    textStyle: {
                        color: '#fff',
                        fontSize: 10,
                    },
                },
                tooltip: {
                    trigger: 'axis',
                    valueFormatter: value =>
                        value ? Number(value).toFixed(2) + 'km²' : value,
                },
                grid: {
                    left: '5%',
                    top: '20%',
                    containLabel: true,
                },
                dataZoom: {
                    end: 30,
                    height: 20,
                    textStyle: {
                        color: '#fff',
                    },
                },
                dataset: {
                    dimensions: [],
                    source: [],
                },
                xAxis: {
                    type: 'category',
                    name: '产地',
                    axisLine: {
                        lineStyle: {
                            color: '#fff',
                        },
                    },
                    axisLabel: {
                        rotate: 0
                    }
                },
                yAxis: {
                    name: '单位/km²',
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#fff',
                        },
                    },
                    splitLine: {
                        lineStyle: {
                            color: '#efefefaf',
                        },
                    },
                },
                series: [],
            },
        };
    },
    computed: {
        // selectedRegionObject() {
        //     return this.$store.state['region'].selectedRegionObject;
        // },
        // administrativeDivision() {
        //     return this.$store.state['thematicMap'].administrativeDivision;
        // },
    },
    methods: {
        async changetChartDataset(datasetSource, colors) {
            if (!datasetSource || datasetSource.length === 0) {
                this.option.dataset.source = datasetSource;
                // 显示暂无数据
                this.option.title.show = true;
                this.option.xAxis.show = false;
                this.option.yAxis.show = false;
                this.option.dataZoom.show = false;
                return;
            }

            this.option.title.show = false;
            this.option.xAxis.show = true;
            this.option.yAxis.show = true;
            this.option.dataZoom.show = true;
            this.option.series = [];
            this.option.dataset.dimensions = Object.keys(datasetSource[0]);
            // 控制系列的数量
            for (
                let index = 0;
                index < this.option.dataset.dimensions.length;
                index++
            ) {
                let name = this.option.dataset.dimensions[index];
                if (name === 'name') {
                    continue;
                }
                // 设置柱子的渐变色
                let colorItem = {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                        {
                            offset: 0,
                            color: colors[name] ? colors[name] : '#fff', // 0% 处的颜色
                        },
                        {
                            offset: 0.5,
                            color: colors[name] ? colors[name] : '#fff',
                        },
                        {
                            offset: 1,
                            color: '#ffffff0f', // 100% 处的颜色
                        },
                    ],
                    global: false, // 缺省为 false
                };
                this.option.series.push({
                    type: 'bar',
                    itemStyle: { color: colorItem },
                });
            }
            // console.log(this.option);
            this.option.dataset.source = datasetSource;
            this.$refs.analyticalChartBar.chart.hideLoading();
        },

        handlerFullScreen() {
            let temp = JSON.parse(JSON.stringify(this.option));
            temp.toolbox = {
                feature: {//可下载为图
                    saveAsImage: {show: true}
                },
                right: '15px'
            };
            temp.grid.top = '12%';
            temp.legend = {
                top: '5%',
            };
            temp.xAxis.axisLine.lineStyle.color = '#000000';
            temp.xAxis.axisLabel.rotate = 15;
            temp.yAxis.axisLine.lineStyle.color = '#000000';
            // 放出分割线
            temp.yAxis.splitLine.lineStyle.color = '#000000';
            // 取消滚动条
            temp.dataZoom = [];
            // echart标题
            temp.title = {
                text: this.headerTitle,
                left: 'center',
                textStyle: {
                    //文字颜色
                    color: '#000',
                    fontSize: '24px',
                },
            };

            temp.tooltip = {
                    trigger: 'axis',
                    valueFormatter: value =>
                        value ? Number(value).toFixed(2) + 'km²' : value,
                }

            this.$emit('handlerFullScreen', temp);
        },
    },
    mounted() {
        // this.createDataSet();
    },
};
