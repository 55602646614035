//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import map3d from '@/views/business/thematic-map/map-3d';
import analyticalChartPie2d from '@/views/business/thematic-map/analytical-chart-pie-2d';
import analyticalChartBar from '@/views/business/thematic-map/analytical-chart-bar';
import {
	statisticWithGroup,
	clipStatisticWithGroup,
	getGeoServerApiParams,
} from '@/utils/geoserver-params';
import {thematicDatas} from '@/assets/datas/datas.js';
import config from '@/config';
import OverviewOfSmokingArea from "@/components/tobacco/overview-of-smoking-area";

export default {
	name: 'thematicMapIndex',
	inject: ['showLoading', 'hideLoading'],
	components: {
		OverviewOfSmokingArea,
		map3d,
		analyticalChartPie2d,
		analyticalChartBar,
	},
	computed: {
		selectedRegionObject: function () {
			return this.$store.state['region'].selectedRegionObject;
		},
		selectedThematicType: function () {
			return this.$store.state['thematicMap'].selectedThematicType;
		},
		administrativeDivision: function () {
			return this.$store.state['thematicMap'].administrativeDivision;
		},
		randomRGBColor: function () {
			return this.$store.state['tobaccoAnalyse'].randomRGBColor;
		},
		selectedTobaccoArea() {
			return this.$store.state['region'].selectedTobaccoArea;
		},
		regionType() {
			return this.$store.state['region'].regionType;
		},
	},
	watch: {
		selectedRegionObject: function () {
			this.datas = thematicDatas[this.selectedThematicType];
			if (this.chooseYear !== 2020) {
				this.datas = null;
			}
			this.handlerEchartsData();
			this.$refs.map3d.loadGeoJson();
		},
	},
	data() {
		return {
			searchTypeOptions: [
				{
					label: '行政区划',
					value: '0',
				},
			],
			searObj: {
				text: '',
			},
			echarts: {
				pie: {
					text: '2016年云南省降雨量分析',
					show: true,
				},
				bar: {
					text: '2016年云南省下级区域降雨量分析',
					show: true,
				},
			},
			datas: {},
			chartLoading: false,
			chartDialog: {
				show: false,
				option: {},
			},
			chooseYear: 2020,
			legendShow: true,
			disableZZQ: false,
			isShow: true,
		};
	},
	methods: {
		handlerLegendClik() {
			this.legendShow = !this.legendShow;
			this.$refs.map3d.getList(this.legendShow);
		},

		handlerClickRegionType(regionType) {
			if (regionType === 'area') {
				// 专题图分类中添加种植区
				this.disableZZQ = false;
				// 切换为行政区划边界

			} else if (regionType === 'tobaccoArea') {
				// 专题图分类中去除种植区
				this.disableZZQ = true;
				// 切换为种植区的行政边界

			}
			// this.$store.dispatch('thematicMap/setThematicLayersAndStylesObj', {
			//     year: this.chooseYear,
			//     disableZZQ: this.disableZZQ
			// });
			// 切换为默认的专题图
			this.$store.dispatch('thematicMap/setSelectedThematicType', '降雨量');
			this.$refs.map3d.loadGeoJson();
			this.handlerThematicTypeClick();
			this.$refs.map3d.loadCurrentAreaBord();
		},

		handlerClickTobaccoArea() {
			// 处理种植区的下级点击
			this.$refs.map3d.loadGeoJson();
			this.handlerEchartsData();
		},

		// 处理专题图类型选择
		handlerThematicTypeClick() {
			// 根据年份获取专题图
			this.$store.dispatch('thematicMap/setThematicLayersAndStylesObj', {
				year: this.chooseYear,
				disableZZQ: this.disableZZQ
			});
			this.datas = thematicDatas[this.selectedThematicType];
			// todo 修改为联动年份的
			if (this.chooseYear !== 2020) {
				this.datas = null;
			}
			this.handlerEchartsData();
			// 切换对应专题图
			this.$refs.map3d.loadThematicByType();
		},

		async handlerEchartsData() {
			this.chartLoading = true;
			let region = '';
			// 种植区的单独处理
			if (this.regionType === 'tobaccoArea') {
				region = this.selectedTobaccoArea.name;
			} else {
				region = this.selectedRegionObject.name;
			}
			// 统计图的名称拼接 （年份+行政区划+专题图分类）
			this.echarts.pie.text = `${this.chooseYear}年${region}-${this.selectedThematicType}分析`;
			this.echarts.bar.text = `${this.chooseYear}年${region}下级区域-${this.selectedThematicType}分析`;

			// 初始化图表数据
			let barDatasetSource = [];
			let pieOptionData = [];

			// 无数据
			if (!this.datas) {
				this.$refs.chartBar.changetChartDataset(barDatasetSource, null);
				this.$refs.chartPie.createPie(pieOptionData, null);
				this.chartLoading = false;
				return;
			}

			let colors = this.datas.colors;

			// 种植区的单独处理
			if (this.regionType === 'tobaccoArea') {
				this.echarts.bar.show = false;
				let getDataObject =
					await this.getClipStatisticWithGroupByGeoJson();
				// 根据配置里的来排序
				this.datas.legendList.forEach(element => {
					let key = element.text;
					const item = getDataObject[key];
					// 单位换算 平方米 =====> 平方千米
					let area = item.area / 1000000;
					pieOptionData.push({name: key, value: area});
				});
				this.$refs.chartPie.createPie(pieOptionData, colors);
				this.chartLoading = false;
				return;
			}

			// 判断行政区划级别，最后一级没有下级区域分析
			if (this.selectedRegionObject.level === 4) {
				this.echarts.bar.show = false;
				let getDataObject =
					await this.getClipStatisticWithGroupByGeoJson();
				// 根据配置里的来排序
				this.datas.legendList.forEach(element => {
					let key = element.text;
					const item = getDataObject[key];
					// 单位换算 平方米 =====> 平方千米
					let area = item.area / 1000000;
					pieOptionData.push({name: key, value: area});
				});
				this.$refs.chartPie.createPie(pieOptionData, colors);
				this.chartLoading = false;
				return;
			}

			// 请求+处理统计图数据
			// 使用了包含上级信息的行政区划wfs，可以直接通过父级code查询下级的geojson，不需要遍历查询下级
			let getDataObject = await this.getClipStatisticWithGroupByGeoJson();
			if (!getDataObject) {
				this.$refs.chartBar.changetChartDataset(barDatasetSource, null);
				this.$refs.chartPie.createPie(pieOptionData, null);
				this.chartLoading = false;
				return;
			}

			let resultPie = {};
			for (const key in getDataObject) {
				const element = getDataObject[key];
				let result = {name: key};
				// 根据配置里的来排序
				this.datas.legendList.forEach(legend => {
					const item = element[legend.text];
					// 单位换算 平方米 =====> 平方千米
					let area = item.area / 1000000;
					result[legend.text] = area;
					if (typeof resultPie[legend.text] == 'undefined') {
						resultPie[legend.text] = area ? area : 0;
					} else {
						resultPie[legend.text] += area;
					}
				});
				barDatasetSource.push(result);
			}
			// 求和之后的饼状图数据
			for (const key in resultPie) {
				const element = resultPie[key];
				pieOptionData.push({name: key, value: element});
			}

			this.echarts.bar.show = true;
			this.$refs.chartBar.changetChartDataset(barDatasetSource, colors);
			this.$refs.chartPie.createPie(pieOptionData, colors);
			this.chartLoading = false;
		},

		// 获取指定geojson切割栅格图的结果
		async getClipStatisticWithGroupByGeoJson() {
			let element = this.regionType === 'tobaccoArea' ? this.selectedTobaccoArea : this.selectedRegionObject;
			let isEmpty = !(this.selectedRegionObject || this.selectedTobaccoArea);
			if (isEmpty) {
				return;
			}
			// 根据行政区划分级来请求不同的layers，获取行政区划的geojson
			let level = this.regionType === 'tobaccoArea' ? 'tobaccoArea' : element.level;
			let layers = null;
			let urlParams = null;
			let url = '';
			switch (level) {
				// 行政区划为省，获取它下级的所有geojson
				case 1:
					layers = this.administrativeDivision[2].layers;
					urlParams = getGeoServerApiParams(layers, 'wfs');
					url =
						`${config.qyGeoApi}?${urlParams}` +
						`&typeName=${layers}` +
						`&service=wfs&version=2.0.0&request=GetFeature&outputFormat=application%2Fjson`;
					break;

				// 行政区划为市，筛选该市的下级的所有geojson
				case 2:
					layers = this.administrativeDivision[3].layers;
					urlParams = getGeoServerApiParams(layers, 'wfs');
					url =
						`${config.qyGeoApi}?${urlParams}` +
						`&cql_filter=City_code='${element.code}'` +
						`&typeName=${layers}` +
						`&service=wfs&version=2.0.0&request=GetFeature&outputFormat=application%2Fjson`;
					break;

				// 行政区划为区县，获取该区县的下级的所有geojson
				case 3:
					layers = this.administrativeDivision[4].layers;
					urlParams = getGeoServerApiParams(layers, 'wfs');
					url =
						`${config.qyGeoApi}?${urlParams}` +
						`&cql_filter=County_cod='${element.code}'` +
						`&typeName=${layers}` +
						`&service=wfs&version=2.0.0&request=GetFeature&outputFormat=application%2Fjson`;
					break;

				// 行政区划为乡镇的，不需要下级
				case 4:
					layers = this.administrativeDivision[4].layers;
					urlParams = getGeoServerApiParams(layers, 'wfs');
					url =
						`${config.qyGeoApi}?${urlParams}` +
						`&cql_filter=code='${element.code}'` +
						`&typeName=${layers}` +
						`&service=wfs&version=2.0.0&request=GetFeature&outputFormat=application%2Fjson`;
					break;

				// 种植区下级
				case 'tobaccoArea':
					layers = this.administrativeDivision['tobaccoArea'].layers;
					urlParams = getGeoServerApiParams(layers, 'wfs');
					url =
						`${config.qyGeoApi}?${urlParams}` +
						`&cql_filter=value='${element.value}'` +
						`&typeName=${layers}` +
						`&service=wfs&version=2.0.0&request=GetFeature&outputFormat=application%2Fjson`;
					break;
				default:
					break;
			}
			let geoJson = await fetch(url).then(resp => {
				if (resp) {
					return resp.json();
				}
			});

			if (
				typeof geoJson.features === 'undefined' ||
				geoJson.totalFeatures === 0
			) {
				return;
			}
			// 遍历features，添加groupName
			geoJson.features.forEach(item => {
				item.properties.groupName = item.properties.Name;
			});

			// 删除wfs请求的geoJson中没必要的属性，防止请求的缓存失效
			let geoJsonResult = {
				type: geoJson.type,
				features: geoJson.features,
			};
			this.datas.geoJson = geoJsonResult;
			// 请求geoserver切割栅格图api
			let getDataObject = await clipStatisticWithGroup(this.datas);
			return getDataObject;
		},

		changeParticularYear(item) {
			this.showLoading('加载样点数据', '#eeeeee2e');
			this.chooseYear = item;

			this.handlerThematicTypeClick();
		},
		async handlertatisticWithGroup() {
			this.chartLoading = true;
			let data = await statisticWithGroup(
				thematicDatas[this.selectedThematicType]
			);
			let colors = this.datas.colors;
			let pieOptionData = [];
			for (const key in data) {
				if (Object.hasOwnProperty.call(data, key)) {
					const element = data[key];
					// 单位换算 平方米 =====> 平方千米
					let area = element.area / 1000000;
					pieOptionData.push({name: key, value: area});
				}
			}
			this.$refs.chartPie.createPie(pieOptionData, colors);

			this.chartLoading = false;
		},
		handlerChartDialogOpen(option) {
			this.chartDialog.show = true;
			this.$nextTick(() => {
				this.chartDialog.option = option;
			});
		},
	},
	mounted() {
		this.$store.dispatch('thematicMap/setThematicLayersAndStylesObj', {
			year: this.chooseYear,
			disableZZQ: this.disableZZQ
		});
		// 默认的专题图
		this.$store.dispatch('thematicMap/setSelectedThematicType', '降雨量');
	},
};
