//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	name: 'thematicTypeContainer',
	data() {
		return {
			thematicType: '降雨量'
		};
	},
	computed: {
		thematicTypeList() {
			return this.$store.state['thematicMap'].thematicTypeList;
		},
		selectedThematicType() {
			return this.$store.state['thematicMap'].selectedThematicType;
		}
	},
	methods: {
		handlerThematicTypeClick() {
			this.$store.dispatch('thematicMap/setSelectedThematicType', this.thematicType);
			this.$emit('handlerThematicTypeClick');
		}
	},
	mounted() {
	},
};
