const data = {
    降雨量: {
        layers: '6d41ca7736aaeaf5ebb62421e1a6b15f',
        style: '45b7e70dc48a1ea227bbe3a0dfc623b9',
        legendList: [
            {
                text: '460~600',
                pixelValue: 677,
                color: '#E1D666',
            },
            {
                text: '600~800',
                pixelValue: 811,
                color: '#a8bc3f',
            },
            {
                text: '800~1000',
                pixelValue: 945,
                color: '#7da129',
            },
            {
                text: '1000~1200',
                pixelValue: 1079,
                color: '#6a991f',
            },
            {
                text: '1200~1400',
                pixelValue: 1213,
                color: '#508826',
            },
            {
                text: '1400~1600',
                pixelValue: 1348,
                color: '#4c8422',
            },
            {
                text: '1600~1800',
                pixelValue: 1482,
                color: '#3f7e1c',
            },
            {
                text: '1800~2000',
                pixelValue: 1616,
                color: '#3e8455',
            },
            {
                text: '2000~2200',
                pixelValue: 1750,
                color: '#3f7381',
            },
            {
                text: '2200~2400',
                pixelValue: 1884,
                color: '#365683',
            },
            {
                text: '2400~2600',
                pixelValue: 2018,
                color: '#2c3e69',
            },
            {
                text: '2600~3100',
                pixelValue: 9999,
                color: '#242c5a',
            },
        ],
        group: {
            '460~600': [0, 677],
            '600~800': [677, 811],
            '800~1000': [811, 945],
            '1000~1200': [945, 1079],
            '1200~1400': [1079, 1213],
            '1400~1600': [1213, 1348],
            '1600~1800': [1348, 1482],
            '1800~2000': [1482, 1616],
            '2000~2200': [1616, 1750],
            '2200~2400': [1750, 1884],
            '2400~2600': [1884, 2018],
            '2600~3100': [2018, 9999],
        },
    },
    土壤氮: {
        layers: '0275bdf894576bf13be72ca38beddf63',
        style: '6be104c3ad5b8bba92b446215b70d2ad',
        legendList: [
            {
                text: '>150',
                pixelValue: 150,
                color: '#377A1F',
            },
            {
                text: '120~150',
                pixelValue: 120,
                color: '#F8E6A6',
            },
            {
                text: '90~120',
                pixelValue: 90,
                color: '#DACE86',
            },
            {
                text: '60~90',
                pixelValue: 60,
                color: '#AB723B',
            },
            {
                text: '30~60',
                pixelValue: 30,
                color: '#F5F1E4',
            },
        ],
        group: {
            '>150': [120, 150],
            '120~150': [60, 120],
            '90~120': [60, 90],
            '60~90': [30, 60],
            '30~60': [0, 30],
        },
    },
    种植区: {
        layers: '7568070c6427f978c89d047aed5bfe20',
        style: '8b03bb6cd257d73faa6b62e3c227ce84',
        legendList: [
            {
                text: '滇东北烟区',
                pixelValue: 1,
                color: '#99A25F',
            },
            {
                text: '滇东南烟区',
                pixelValue: 2,
                color: '#C7D343',
            },
            {
                text: '滇东烟区',
                pixelValue: 3,
                color: '#DEC0CB',
            },
            {
                text: '滇西南烟区',
                pixelValue: 4,
                color: '#B46292',
            },
            {
                text: '滇西烟区',
                pixelValue: 5,
                color: '#EBDA8D',
            },
            {
                text: '滇中烟区',
                pixelValue: 6,
                color: '#F26610',
            },
            {
                text: '未种植区',
                pixelValue: 7,
                color: '#EBE2E2',
            },
        ],
        group: {
            滇东北烟区: [0, 1],
            滇东南烟区: [1, 2],
            滇东烟区: [2, 3],
            滇西南烟区: [3, 4],
            滇西烟区: [4, 5],
            滇中烟区: [5, 6],
            未种植区: [6, 7],
        },
    },
    品质特点: {
        layers: '328d1f1acd821f38c3aeb592332634e0',
        style: 'd10b144932075aeb3490bbb3ddc6e4f1',
        legendList: [
            {
                text: '中高糖中低氮中低石油醚最适宜区',
                pixelValue: 10,
                color: '#227422',
            },
            {
                text: '中高糖中低氮中低石油醚适宜区',
                pixelValue: 11,
                color: '#71C271',
            },
            {
                text: '中高糖中低氮中低石油醚次适宜区',
                pixelValue: 9,
                color: '#D2EED2',
            },
            {
                text: '高糖低氮低石油醚最适宜区',
                pixelValue: 8,
                color: '#881420',
            },
            {
                text: '高糖低氮低石油醚适宜区',
                pixelValue: 7,
                color: '#C45D67',
            },
            {
                text: '高糖低氮低石油醚次适宜区',
                pixelValue: 3,
                color: '#F2C5CA',
            },
            {
                text: '中低糖中高氮中高石油醚最适宜区',
                pixelValue: 13,
                color: '#2121A0',
            },
            {
                text: '中低糖中高氮中高石油醚适宜区',
                pixelValue: 12,
                color: '#5352A0',
            },
            {
                text: '中低糖中高氮中高石油醚次适宜区',
                pixelValue: 4,
                color: '#B9B8EE',
            },
            {
                text: '低糖高氮中高石油醚最适宜区',
                pixelValue: 5,
                color: '#744F35',
            },
            {
                text: '低糖高氮中高石油醚适宜区',
                pixelValue: 2,
                color: '#9C7558',
            },
            {
                text: '低糖高氮中高石油醚次适宜区',
                pixelValue: 1,
                color: '#A79283',
            },
        ],
        group: {
            中高糖中低氮中低石油醚最适宜区: [9, 10],
            中高糖中低氮中低石油醚适宜区: [10, 11],
            中高糖中低氮中低石油醚次适宜区: [8, 9],
            高糖低氮低石油醚最适宜区: [7, 8],
            高糖低氮低石油醚适宜区: [6, 7],
            高糖低氮低石油醚次适宜区: [2, 3],
            中低糖中高氮中高石油醚最适宜区: [12, 13],
            中低糖中高氮中高石油醚适宜区: [11, 12],
            中低糖中高氮中高石油醚次适宜区: [3, 4],
            低糖高氮中高石油醚最适宜区: [4, 5],
            低糖高氮中高石油醚适宜区: [1, 2],
            低糖高氮中高石油醚次适宜区: [0, 1],
        },
    },
    日照: {
        layers: '489dd8d2af6dfb9ed7ad788361b33aaa',
        style: '3b1a8f54f84c537c131181a7af350421',
        legendList: [
            {
                text: '123~170',
                pixelValue: 5,
                color: '#549819',
            },
            {
                text: '170.1~210',
                pixelValue: 6,
                color: '#6ABF30',
            },
            {
                text: '210.1~250',
                pixelValue: 1,
                color: '#B9E66D',
            },
            {
                text: '250.1~290',
                pixelValue: 3,
                color: '#E1EA42',
            },
            {
                text: '290.1~330',
                pixelValue: 7,
                color: '#F1F2CF',
            },
            {
                text: '330.1~370',
                pixelValue: 4,
                color: '#DAD68F',
            },
            {
                text: '370.1~410',
                pixelValue: 2,
                color: '#F2C935',
            },
            {
                text: '410.1~450',
                pixelValue: 8,
                color: '#CF2A9B',
            },
        ],
        group: {
            '123~170': [4, 5],
            '170.1~210': [5, 6],
            '210.1~250': [0, 1],
            '250.1~290': [2, 3],
            '290.1~330': [6, 7],
            '330.1~370': [3, 4],
            '370.1~410': [1, 2],
            '410.1~450': [7, 8],
        },
    },
    有机质: {
        layers: '72da6b93c9d8f1d9c6367d969bb8aa95',
        style: '3ebf4af8ea42efeaec4831a1b08fc421',
        legendList: [
            {
                text: '>40',
                pixelValue: 2,
                color: '#6CD8EE',
            },
            {
                text: '30~40',
                pixelValue: 1,
                color: '#69D95B',
            },
            {
                text: '20~30',
                pixelValue: 4,
                color: '#7EE87E',
            },
            {
                text: '10~20',
                pixelValue: 3,
                color: '#E8DF95',
            },
            {
                text: '6~10',
                pixelValue: 5,
                color: '#BA6F7C',
            },
        ],
        group: {
            '>40': [1, 2],
            '30~40': [0, 1],
            '20~30': [3, 4],
            '10~20': [2, 3],
            '6~10': [4, 5],
        },
    },
    土壤钾: {
        layers: 'bd40eb65a97e70415a8771b7987f951a',
        // style: '6a7a0e23b32864b49f9e6d9938b1d5b1',
        style: '7ac7e87eba93e74b8c37d69d7e176c48',
        legendList: [
            {
                text: '>200',
                pixelValue: 30,
                color: '#d27a77',
            },
            {
                text: '150~200',
                pixelValue: 84,
                color: '#d49b75',
            },
            {
                text: '100~150',
                pixelValue: 138,
                color: '#E1B860',
            },
            {
                text: '50~100',
                pixelValue: 192,
                color: '#dcd973',
            },
            {
                text: '30~50',
                pixelValue: 246,
                color: '#b7c875',
            },
            {
                text: '<30',
                pixelValue: 300,
                color: '#9cc467',
            },
        ],
        group: {
            '>200': [0, 30],
            '150~200': [30, 50],
            '100~150': [50, 100],
            '50~100': [100, 150],
            '30~50': [150, 200],
            '<30': [200, 300],
        },
    },
};

// todo 年份的筛选
// 除了group，其他字段在这里处理
let result = {};
for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
        const legendList = data[key].legendList;
        let temp = {};
        let tempColor = {};
        temp[legendList[0].text] = [0, legendList[0].pixelValue];
        // tempColor[legendList[0].text] = legendList[0].color;
        // let group = temp;
        // { 1: [0, 30], 2: [30, 79] }
        // for (let index = 1; index < legendList.length; index++) {
        //     const item = legendList[index];
        //     temp[item.text] = [
        //         legendList[index - 1].pixelValue,
        //         item.pixelValue,
        //     ];
        //     tempColor[item.text] = item.color;
        // }
        for (let index = 0; index < legendList.length; index++) {
            const item = legendList[index];
            tempColor[item.text] = item.color;
        }
        result[key] = {
            layers: data[key].layers,
            colors: tempColor,
            // group: temp,
            group: data[key].group,
            bandIndex: 0,
            legendList: legendList,
        };
        // if (key === '种植区') {
        // 	result['种植区'].group = temp;
        // }
    }
}

export const thematicDatas = result;
// {
//     '降雨量': {
//         group: [],
//         bandIndex: 0
//     }
// }
