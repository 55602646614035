//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'analyticalChartPie',
    props: {
        headerTitle: {
            type: String,
            default: '2016年云南省降雨量分析',
        },
        chartLoading: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            colors: {},
            option: {
                grid: {
                    containLabel: true,
                },
                legend: {
                    type: 'scroll',
                    top: 0,
                    pageIconColor: '#fff',
                    pageTextStyle: {
                        color: '#fff',
                    },
                    textStyle: {
                        color: '#fff',
                        fontSize: 10,
                    },
                },
                tooltip: {
                    trigger: 'item',
                    valueFormatter: value =>
                        value ? Number(value).toFixed(2) + 'km²' : value,
                },
                toolbox: {
                    show: false,
                },
                series: [
                    {
                        name: '面积',
                        type: 'pie',
                        roseType: 'radius',
                        radius: ['15%', '60%'],
                        center: ['50%', '50%'],
                        // hoverAnimation: true,
                        emphasis: {
                            scale: true,
                        },
                        itemStyle: {},
                        label: {
                            formatter: params => {
                                return (
                                    '{icon|●}{name|' +
                                    params.name +
                                    '}\n{value|' +
                                    Number(params.value).toFixed(2) +
                                    'km²}'
                                );
                            },
                            rich: {
                                icon: {
                                    fontSize: 12,
                                    color: 'inherit',
                                },
                                name: {
                                    fontSize: 12,
                                    fontWeight: 'bolder',
                                    padding: [0, 0, 0, 10],
                                    color: '#fefefe',
                                },
                                value: {
                                    fontSize: 10,
                                    padding: [5, 0, 0, 20],
                                    color: '#fefefe',
                                },
                            },
                        },
                        data: [],
                    },
                ],
            },
            optionFullScreen: {
                grid: {
                    containLabel: true,
                    top: '12%',
                },
                legend: {
                    top: '5%',
                },
                title: {
                    text: '',
                    left: 'center',
                    textStyle: {
                        //文字颜色
                        color: '#000',
                        fontSize: '24px',
                    },
                },
                tooltip: {
                    trigger: 'item',
                    valueFormatter: value =>
                        value ? Number(value).toFixed(2) + 'km²' : value,
                },
                toolbox: {
					show: true,
					feature: {//可下载为图
						saveAsImage: {show: true}
					},
                    right: '15px'
				},
                series: [
                    {
                        name: '面积',
                        type: 'pie',
                        roseType: 'radius',
                        radius: ['15%', '60%'],
                        center: ['50%', '50%'],
                        // hoverAnimation: true,
                        emphasis: {
                            scale: true,
                        },
                        itemStyle: {},
                        label: {
                            formatter: params => {
                                return (
                                    '{icon|●}{name|' +
                                    params.name +
                                    '}\n{value|' +
                                    Number(params.value).toFixed(2) +
                                    'km²}'
                                );
                            },
                            rich: {
                                icon: {
                                    fontSize: 12,
                                    color: 'inherit',
                                },
                                name: {
                                    fontSize: 12,
                                    fontWeight: 'bolder',
                                    padding: [0, 0, 0, 10],
                                    color: '#000',
                                },
                                value: {
                                    fontSize: 10,
                                    padding: [5, 0, 0, 20],
                                    color: '#000',
                                },
                            },
                        },
                        data: [],
                    },
                ],
            }
        };
    },

    methods: {
        createPie(pieOptionData, colors) {
            this.colors = colors;
            pieOptionData.forEach(v => {
                let name = v.name;
                v.labelLine = {
                    lineStyle: {
                        width: 1,
                        color: colors[name],
                    },
                };
                v.itemStyle = {
                    color: colors[name],
                };
            });

            this.option.series[0].data = pieOptionData;
            this.optionFullScreen.series[0].data = pieOptionData;
            // console.log(this.option, 'this.option');
        },
        getRich() {
            // echart 的自定义rich名称不能有特殊符号，测试过仅支持字母和数字
            let result = {};
            // let i = 0;
            for (let key in this.colors) {
                let str = key.split('~')[0];
                result[`hr${str}`] = {
                    backgroundColor: this.colors[key],
                    borderRadius: 3,
                    width: 3,
                    height: 3,
                    padding: [3, 3, 0, -12],
                };
                result[`a${str}`] = {
                    padding: [-11, 6, -20, 6],
                    color: this.colors[key],
                    // backgroundColor: '#fff',
                    // borderRadius: [4, 4, 4, 4],
                    fontSize: 12,
                };
                // i++;
            }
            return result;
        },
        handlerFullScreen() {
            this.optionFullScreen.title.text = this.headerTitle;
            this.$emit('handlerFullScreen', this.optionFullScreen);
        },
    },

    mounted() {},
};
